import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import Heading from "./components/Main/TextElement/Heading/Heading";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import SOS from "./pages/Products/SOS/SOS";
import Cronology from "./pages/Products/Cronology/Cronology";
import Detox from "./pages/Products/Detox/Detox";
import Alquimia from "./pages/Products/Alquimia/Alquimia";
import Lovely from "./pages/Products/Lovely/Lovely";
import TheFirst from "./pages/Products/TheFirst/TheFirst";
import Academy from "./pages/Academy/Academy";
import AOS from "aos";
import "aos/dist/aos.css";
import Blog from "./pages/Blog/Blog";
import Contact from "./pages/Contact/Contact";
import News from "./pages/News/News";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import ScrollToTop from "./ScrollToTop";
import Policy from "./pages/Policy/Policy";

AOS.init({ duration: 1000 });

export default function App() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Suspense fallback={<Heading type="h2" items={[]} htmlFor="">Ładowanie...</Heading>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/o-nas" element={<AboutUs />} />
          <Route path="/produkty/sos" element={<SOS />} />
          <Route path="/produkty/cronology" element={<Cronology />} />
          <Route path="/produkty/detox" element={<Detox />} />
          <Route path="/produkty/alquimia" element={<Alquimia />} />
          <Route path="/produkty/lovely" element={<Lovely />} />
          <Route path="/produkty/the-first" element={<TheFirst />} />
          <Route path="/akademia" element={<Academy />} />
          <Route path="/salony-partnerskie" element={<Home />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<News />} />
          <Route path="/auth/signin" element={<SignIn />} />
          <Route path="/auth/signup" element={<SignUp />} />
          <Route path="/polityka-prywatnosci" element={<Policy />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}